import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";
import { Select, MenuItem } from '@mui/material';
import tnc from 'assets/images/MyWayTNC.pdf';
import policy from 'assets/images/MyWayPolicy.pdf';
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/background-cover-auth-signup.png";
import VuiDropzone from "components/VuiDropzone";
import { useLocation } from 'react-router-dom';
import axios from 'axios';

function RegisterForm() {

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };
  
  const history = useHistory();
  const token = localStorage.getItem("token");
  if (token) {
    history.push("/Dashboard");
  }
  const location = useLocation();
  const { fname, lname, ic, pn, em, rc } = location.state || {};
  const [loading, setloading] = useState(false);
  const [firstName, setFirstName] = useState(fname);
  const [lastName, setLastName] = useState(lname);
  const [nric, setNric] = useState(ic);
  const [phoneNumber, setPhoneNumber] = useState(pn);
  const [email, setEmail] = useState(em);
  const [referralCode, setReferralCode] = useState(rc);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [countryCode, setCountryCode] = useState('+60');
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    nric: "",
    phoneNumber: "",
    email: "",
    referralCode: "",
    termsAccepted: "",
  });

  useEffect(() => {
    const referral = getCookie('referral');
    if (referral) {
      setReferralCode(referral);
    }
  }, []);

  const handleRoutes = (path) => {
    history.push(path);
  };

const handleSubmit = async () => {
  const newErrors = {};
  setloading(true);

  // Validate inputs
  if (!firstName) {
    newErrors.firstName = "First name is required";
  }
  if (!lastName) {
    newErrors.lastName = "Last name is required";
  }
  if (!nric) {
    newErrors.nric = "NRIC/ID is required";
  } else {
    try {
      const { data: checkNricData } = await axios.get(`/checkNric`, { params: { value: nric } });
      if (checkNricData.status === "fail") {
        newErrors.nric = "NRIC already taken, please proceed with Login";
      }
    } catch (error) {
      console.error("Error checking NRIC:", error);
      newErrors.nric = "Failed to validate NRIC";
    }
  }

  if (!phoneNumber) {
    newErrors.phoneNumber = "Phone number is required";
  } else {
    let newPhone = phoneNumber.charAt(0) === "0" ? phoneNumber.substring(1) : phoneNumber;
    setPhoneNumber(newPhone);

    if (newPhone.length < 8 || newPhone.length > 10) {
      newErrors.phoneNumber = "Invalid format, example: 123456789";
    } else {
      try {
        const { data: checkPhoneData } = await axios.get(`/checkPhone`, { params: { value: newPhone } });
        if (checkPhoneData.status === "fail") {
          newErrors.phoneNumber = "Phone already taken, please proceed with Login";
        }
      } catch (error) {
        console.error("Error checking phone number:", error);
        newErrors.phoneNumber = "Failed to validate phone number";
      }
    }
  }

  if (!email) {
    newErrors.email = "Email is required";
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
    newErrors.email = "Email format is incorrect";
  } else {
    try {
      const { data: checkEmailData } = await axios.get(`/checkEmail`, { params: { value: email } });
      if (checkEmailData.status === "fail") {
        newErrors.email = "Email already taken, please proceed with Login";
      }
    } catch (error) {
      console.error("Error checking email:", error);
      newErrors.email = "Failed to validate email";
    }
  }

  if (!referralCode) {
    newErrors.referralCode = "Referral code is required";
  } else {
    try {
      const { data: checkReferralData } = await axios.get(`/checkReferral`, { params: { value: referralCode } });
      if (checkReferralData.status === "error") {
        newErrors.referralCode = "Invalid Referral Code";
      }
    } catch (error) {
      console.error("Error checking referral code:", error);
      newErrors.referralCode = "Failed to validate referral code";
    }
  }

  if (!termsAccepted) {
    newErrors.termsAccepted = "Please accept the terms & conditions and privacy policy";
  }

  if (Object.keys(newErrors).length > 0) {
    setErrors(newErrors);
    setloading(false);
    return;
  }

  try {
    const response = await axios.post("/registerOtp", new URLSearchParams({
      email: email,
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.data.status === "fail") {
      throw new Error("Failed to send OTP");
    }

    history.push("/OTPRegister", {
      firstName,
      lastName,
      nric,
      phoneNumber,
      email,
      referralCode,
    });
  } catch (error) {
    console.error("Error sending OTP:", error);
    setErrors({ ...newErrors, otp: "Failed to send OTP" });
  } finally {
    setloading(false);
  }
};

return (
  <CoverLayout
  top="40px"
  title="/Plan"
  color="white"
  description="Let’s get you activated!"
  image={bgImage}
  premotto={"COMMUNITY POWERED COMMERCE"}
  motto={"Togethër"}
  cardContent
  >
  <VuiBox
  style={{ background: "linear-gradient(to right, #FFFFFF, #FFFFFF)", borderRadius: "30px" }}
  component="form"
  role="form"
  borderRadius="inherit"
  p="30px"
  >
  <VuiBox
  style={{
    background: "linear-gradient(to right, #DDDDDD, #DDDDDD)",
    borderRadius: "15px",
  }}
  component="form"
  role="form"
  borderRadius="inherit"
  p="10px"
  >
  <VuiInput
  placeholder="First Name"
  size="small"
  required
  value={firstName}
  onChange={(e) => setFirstName(e.target.value)}
  />
  <div style={{ color: "red", fontSize: "0.8rem", marginTop: "4px" }}>
  {errors.firstName}
  </div>
  </VuiBox>

  <VuiBox
  style={{
    background: "linear-gradient(to right, #DDDDDD, #DDDDDD)",
    borderRadius: "15px",
    marginTop: "14px",
  }}
  component="form"
  role="form"
  borderRadius="inherit"
  p="10px"
  >
  <VuiInput
  placeholder="Last Name"
  size="small"
  required
  value={lastName}
  onChange={(e) => setLastName(e.target.value)}
  />
  <div style={{ color: "red", fontSize: "0.8rem", marginTop: "4px" }}>
  {errors.lastName}
  </div>
  </VuiBox>

  <VuiBox
  style={{
    background: "linear-gradient(to right, #DDDDDD, #DDDDDD)",
    marginTop: "14px",
    borderRadius: "15px",
  }}
  component="form"
  role="form"
  borderRadius="inherit"
  p="10px"
  >
  <VuiInput
  placeholder="NRIC/ ID"
  size="small"
  required
  value={nric}
  onChange={(e) => {
    const input = e.target.value;
    if (input.length <= 12) {
      setNric(input);
    }
  }}
  />
  <div style={{ color: "red", fontSize: "0.8rem", marginTop: "4px" }}>{errors.nric}</div>
  </VuiBox>

  <VuiTypography
  mt={1}
  variant="body2"
  fontWeight="regular"
  align="left"
  style={{ color: "#000000" }}
  >
  Ensure NRIC is accurate as we use it to verify your identity.
  </VuiTypography>

  <VuiBox
  style={{
    background: "linear-gradient(to right, #DDDDDD, #DDDDDD)",
    marginTop: "24px",
    display: "flex",
    borderRadius: "15px",
  }}
  component="form"
  role="form"
  borderRadius="inherit"
  p="10px"
  >

  <VuiTypography
  mt={1}
  variant="body2"
  align="center"
  style={{ color: "#000000",paddingBottom:"8px" }}
  >
  +60
  </VuiTypography>

  <VuiInput
  placeholder="Phone Number"
  size="small"
  required
  value={phoneNumber}
  onChange={(e) => setPhoneNumber(e.target.value)}
  />
  </VuiBox>
  <div style={{ color: "red", fontSize: "0.8rem", marginTop: "4px" }}>
  {errors.phoneNumber}
  </div>

  <VuiTypography
  mt={1}
  variant="body2"
  fontWeight="regular"
  align="left"
  style={{ color: "#000000" }}
  >
  Ensure phone number is correct as we may send important announcements via your number
  </VuiTypography>

  <VuiBox
  style={{
    background: "linear-gradient(to right, #DDDDDD, #DDDDDD)",
    marginTop: "24px",
    borderRadius: "15px",
  }}
  component="form"
  role="form"
  borderRadius="inherit"
  p="10px"
  >
  <VuiInput
  placeholder="Email"
  size="small"
  required
  value={email}
  onChange={(e) => setEmail(e.target.value)}
  />
  <div style={{ color: "red", fontSize: "0.8rem", marginTop: "4px" }}>{errors.email}</div>
  </VuiBox>

  <VuiTypography
  mt={1}
  variant="body2"
  fontWeight="regular"
  align="left"
  style={{ color: "#000000" }}
  >
  Ensure Email is accurate. we will send OTP to this email
  </VuiTypography>

  <VuiBox
  style={{
    background: "linear-gradient(to right, #DDDDDD, #DDDDDD)",
    marginTop: "20px",
    borderRadius: "15px",
  }}
  component="form"
  role="form"
  borderRadius="inherit"
  p="10px"
  >
  <VuiInput
  placeholder="Referral Code"
  size="small"
  value={referralCode}
  onChange={(e) => setReferralCode(e.target.value)}
  />
  <div style={{ color: "red", fontSize: "0.8rem", marginTop: "4px" }}>
  {errors.referralCode}
  </div>
  </VuiBox>

  <div style={{ display: "flex", alignItems: "center", marginTop: "16px" }}>
  <input
  type="checkbox"
  checked={termsAccepted}
  onChange={(e) => setTermsAccepted(e.target.checked)}
  />
  <span style={{ marginLeft: ".5rem", fontSize: "0.8rem" }}>
  I accept the <a href={tnc} target="_blank" rel="noopener noreferrer" style={{ color:"#5d9710",textDecoration: "underline" }}>Terms & Conditions</a> and <a href={policy} style={{color:"#5d9710", textDecoration: "underline"}} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
  </span>
  </div>
  <div style={{ color: "red", fontSize: "0.8rem", marginTop: "4px" }}>
  {errors.termsAccepted}
  </div>

  <VuiBox mt={4}>
  <VuiButton
  onClick={handleSubmit}
  size="large"
  style={{
    fontSize: "small",
    marginTop: "10px",
    color: "white",
    width: "100%",
    backgroundColor: "#8BC63D",
  }}
  variant="contained"
  fullWidth
  disabled={loading}
  >
  {loading ? "Loading..." : "NEXT"}
  </VuiButton>{" "}
  <VuiButton
  onClick={() => handleRoutes("/Login")}
  size="large"
  style={{
    fontSize: "small",
    marginTop: "10px",
    color: "white",
    width: "100%",
    backgroundColor: "black",
  }}
  variant="contained"
  fullWidth
  >
  BACK
  </VuiButton>
  </VuiBox>
  </VuiBox>
  </CoverLayout>
  );
}

export default RegisterForm;
