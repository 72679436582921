import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/background-cover-auth-signup.png";

function SignIn() {
  const history = useHistory();
  const [packages, setPackages] = useState([]);
  //check login
  const token = localStorage.getItem("token");

  if (!token) {
    history.push("/Welcome");
  }
  const logout = async () => {
    localStorage.clear();
    window.location.reload();
  };
  useEffect(() => {
    fetch('/getPackage')
    .then(response => response.json())
    .then(data => {
      setPackages(data);
    })
    .catch(error => {
      console.error("There was an error fetching the packages!", error);
    });
  }, []);

  const handleRoutes = (path) => {
    history.push(path);
  };

  const handlePackageClick = (pkgId,pkgname) => {
    localStorage.setItem('pkgname',pkgname);
    localStorage.setItem('packageId',pkgId);
    history.push( '/Pick-Payment');
  };
  return (
    <CoverLayout
    top="40px"
    title="/Plan"
    color="white"
    description="Let’s get you activated!"
    image={bgImage}
    premotto={"COMMUNITY POWERED COMMERCE"}
    motto={"Togethër"}
    cardContent
    >
    <VuiTypography variant="h6" fontWeight="bold" align="center" style={{ color: "#000000" }}>Select Package</VuiTypography>

    {packages.map((pkg) => (
      <VuiBox
      key={pkg.id}
      style={{
        border: pkg.is_promo === "1" ? "#8BC63D 5px solid" : "linear-gradient(to right, #FFFFFF, #FFFFFF)",
        background: "linear-gradient(to right, #FFFFFF, #FFFFFF)",
        borderRadius: "30px",
        marginTop: "24px"
      }}
      component="form"
      role="form"
      borderRadius="inherit"
      p="45px"
      onClick={() => handlePackageClick(pkg.payment_link,pkg.id)}

      >
      {pkg.is_promo === "1" && (
        <>
        <VuiTypography variant="h4" fontWeight="bold" align="left" style={{ color: "#427400", textDecoration:"underline"}}>Early Bird Promotion *</VuiTypography>
        <VuiTypography variant="h6" fontWeight="medium" align="left" style={{ color: "#000000" }}>* only valid for 100 units</VuiTypography>
        <VuiTypography mb={4} variant="h6" fontWeight="medium" align="left" style={{ color: "#000000" }}>first come first serve</VuiTypography>
        </>
        )}
      <VuiTypography mt={1} variant="h6" fontWeight="bold" align="left" style={{ color: "#000000",textDecoration:"underline" }}>    <div dangerouslySetInnerHTML={{ __html: pkg.title }} /></VuiTypography>
      <VuiTypography mt={1} variant="h6" fontWeight="bold" align="left" style={{ color: "#000000" }}>{pkg.description}</VuiTypography>
      <VuiTypography mt={1} variant="h6" fontWeight="medium" align="left" style={{ color: "#000000" }}>{pkg.description2}</VuiTypography>
      </VuiBox>
      ))}

    <VuiBox mt={4}>
    <VuiButton onClick={logout} size="large" style={{ fontSize: "small", marginTop: "10px", color: "white", width: "100%", backgroundColor: "#8BC63D" }} variant="contained" fullWidth>Logout</VuiButton>
    </VuiBox>
    </CoverLayout>
    );
}

export default SignIn;
