import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import TextField from '@mui/material/TextField';
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/background-cover-auth-signup.png";
import { Input, Typography,Box } from "@mui/material";
import axios from 'axios';

function VerifyOTP() {
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const [resendDisabled, setResendDisabled] = useState(true); // Initially hide the resend button
  const [countdown, setCountdown] = useState(120); // 2 minutes countdown
  const history = useHistory();
  const email = localStorage.getItem('email');
  const token = localStorage.getItem('token');
  if(token){
    history.push('/Dashboard');
  }
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          clearInterval(timer);
          setResendDisabled(false); // Show the resend button after 2 minutes
        }
        return prevCountdown > 0 ? prevCountdown - 1 : 0;
      });
    }, 1000);

    return () => clearInterval(timer); // Clean up the interval on component unmount
  }, []);

  const handleRoutes = (path) => {
    history.push(path);
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post('/verifyLoginOtp', {
        email: email,
        otp: otp,
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (!response.status === 200) {
        throw new Error('Failed to verify OTP');
      }

      const responseData = response.data;

      if (responseData.status === 'fail') {
        setMessage('Invalid OTP. Please try again.');
      } else {
        localStorage.setItem('token', responseData.token);
        history.push('/Dashboard');
      }
    } catch (error) {
      setMessage('Invalid OTP. Please try again.');
    }
  };

  const handleResendOTP = () => {
    setCountdown(120);
    setResendDisabled(true);
  };

  return (
    <CoverLayout
    top="40px"
    title="OTP Verification"
    color="white"
    description="Please enter the OTP sent to your email."
    image={bgImage}
    premotto={"COMMUNITY POWERED COMMERCE"}
    motto={"Togethër"}
    cardContent
    >
    <VuiBox
    style={{ background: "linear-gradient(to right, #FFFFFF, #FFFFFF)", borderRadius: "30px" }}
    borderRadius="inherit"
    p="45px"
    >
    <VuiTypography variant="body2" fontWeight="regular" align="center" size='10px' style={{color:"#000000"}}>We have sent an OTP to your email <span>{email}</span></VuiTypography>
    <VuiTypography variant="body2" fontWeight="regular" align="center" size='10px' style={{color:"#000000",marginTop:"24px"}}>
    {resendDisabled ? `Resend OTP(${countdown})` : "Resend OTP"}
    </VuiTypography>

    <Box display="flex" justifyContent="center" mt={2}>
    <TextField
    placeholder="OTP"
    variant="outlined"
    value={otp} 
    onChange={(e) => setOtp(e.target.value)} 
    inputProps={{ style: { textAlign: 'center', fontWeight: 'bold',fontSize:"large" } ,maxLength: 4 }} 
    />
    </Box>
    <VuiTypography variant="button" color="error" align="center">
    {message}
    </VuiTypography>

    <VuiButton
    onClick={handleLogin}
    size="large"
    style={{fontSize:"small",marginTop: "10px", color: "white", width: "100%", backgroundColor: "#8BC63D"}}
    variant="contained"
    fullWidth
    >
    VERIFY
    </VuiButton>

    <VuiButton
    onClick={() => history.goBack()}
    size="large"
    style={{fontSize:"small",marginTop: "10px", color: "white", width: "100%", backgroundColor: "black"}}
    variant="contained"
    fullWidth
    disabled={resendDisabled}
    >
    Resend/Edit
    </VuiButton>
    </VuiBox>
    </CoverLayout>
    );
  }

  export default VerifyOTP;
