import React, { useEffect, useState } from "react";
import VuiBox from "components/VuiBox";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { useHistory } from "react-router-dom";
import bgImage from "assets/images/DashboardBgImage.png";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import { Divider, List, ListItem } from "@mui/material";
import DashboardLayout from "layouts/DashboardLayout";

function Refferal() {
  const history = useHistory();
  const token = localStorage.getItem("token");
  const [referralData, setReferralData] = useState([]);

  // Check login
  if (!token) {
    history.push("/Welcome");
  }

  async function fetchData() {
    try {
      const response = await fetch("/getBonus", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      // Transform the data
      const transformedData = [
        {
          teirName: "Teir 1",
          teirPoints: data.ref1.total,
          users: data.ref1.data.map((user) => {
            const [userName, status, mobileNumber] = user.split(", ");
            return { userName, status, mobileNumber };
          }),
        },
        {
          teirName: "Teir 2",
          teirPoints: data.ref2.total,
          users: data.ref2.data.map((user) => {
            const [userName, status, mobileNumber] = user.split(", ");
            return { userName, status, mobileNumber };
          }),
        },
        {
          teirName: "Teir 3",
          teirPoints: data.ref3.total,
          users: data.ref3.data.map((user) => {
            const [userName, status, mobileNumber] = user.split(", ");
            return { userName, status, mobileNumber };
          }),
        },
      ];

      setReferralData(transformedData); // Update state with transformed data
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleRoutes = (path) => {
    history.push(path);
  };

  return (
    <DashboardLayout
      top="40px"
      title="/Plan"
      color="white"
      description="Let’s get you activated!"
      image={bgImage}
      premotto={"COMMUNITY POWERED COMMERCE"}
      motto={"Togethër"}
      cardContent
    >
      <VuiBox
        style={{ background: "white", borderRadius: "30px", marginTop: "20px" }}
        component="form"
        role="form"
        borderRadius="inherit"
        p="30px 30px"
      >
        <VuiTypography variant="h5" fontWeight="bold" align="center" style={{ color: "black" }}>
          Your Referrals
        </VuiTypography>
        <Divider sx={{ width: "100%", backgroundColor: "black", border: "0.7px solid" }}></Divider>

        <List
          sx={{
            listStyleType: "disc",
            listStylePosition: "inside",
          }}
        >
          {referralData.map((tier, index) => (
            <List key={index}>
              <ListItem style={{ color: "#8BC63D" }} sx={{ display: "list-item", mt: "40px" }}>
                <VuiTypography
                  variant="h6"
                  fontSize="15pt"
                  fontWeight="bold"
                  align="left"
                  style={{ color: "#8BC63D", display: "inline" }}
                >
                  {tier.teirName} : {tier.teirPoints}
                </VuiTypography>
              </ListItem>
              <ListItem>
                <List
                  sx={{
                    listStyleType: "disc",
                    listStylePosition: "inside",
                  }}
                >
                  {tier.users.map((user, userIndex) => (
                    <ListItem
                      key={userIndex}
                      style={{ color: "#8BC63D" }}
                      sx={{ display: "list-item" }}
                    >
                      <VuiTypography
                        variant="h6"
                        fontSize="14pt"
                        fontWeight="semibold"
                        align="left"
                        style={{ color: "black", display: "inline" }}
                      >
                        {user.userName} ,
                        <VuiTypography
                          display={"inline"}
                          variant="h6"
                          fontWeight="semibold"
                          align="left"
                          style={{ color: "grey" }}
                        >
                          {user.status} , {user.mobileNumber}
                        </VuiTypography>
                      </VuiTypography>
                    </ListItem>
                  ))}
                </List>
              </ListItem>
            </List>
          ))}
        </List>

        <VuiBox mt={4}>
          <VuiButton
            onClick={() => handleRoutes("/Dashboard")}
            size="large"
            style={{
              fontSize: "small",
              marginTop: "10px",
              color: "white",
              width: "100%",
              backgroundColor: "#8BC63D",
            }}
            variant="contained"
            fullWidth
          >
            CLOSE
          </VuiButton>
        </VuiBox>
      </VuiBox>
    </DashboardLayout>
  );
}

export default Refferal;
