import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import TextField from '@mui/material/TextField';
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/background-cover-auth-signup.png";
import axios from 'axios';

function SignIn() {
  const [loading, setloading] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const history = useHistory();
  const token = localStorage.getItem('token');
  if(token){
    history.push('/Dashboard');
  }
  const handleRoutes = (path) => {
    history.push(path);
  };

const handleOtpRequest = async () => {
  setloading(true);

  if (!email) {
    setloading(false);
    setMessage('Please enter your email.');
    return;
  }

  if (!isValidEmail(email)) {
    setloading(false);
    setMessage('Please enter a valid email address.');
    return;
  }

  try {
    const response = await axios.post('/loginOtp', new URLSearchParams({
      email: email,
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.status !== 200 || response.data.status === 'fail') {
      throw new Error(response.data.message || 'Failed to send OTP');
    }

    setMessage('OTP sent successfully');
    localStorage.setItem('email', email);
    setTimeout(() => handleRoutes('/OTP'), 1000);
  } catch (error) {
    setMessage(error.message || 'This email address is not registered. Please check your email or sign up.');
  } finally {
    setloading(false);
  }
};

// Email validation function
function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

return (
 <CoverLayout
 top="40px"
 title="/Plan"
 color="white"
 description="Let’s get you activated!"
 image={bgImage}
 premotto={"COMMUNITY POWERED COMMERCE"}
 motto={"Togethër"}
 cardContent
 >
 <VuiBox
 style={{ background: "linear-gradient(to right, #FFFFFF, #FFFFFF)", borderRadius: "30px" }}
 borderRadius="inherit"
 p="45px"
 >
 <VuiTypography variant="h6" fontWeight="bold" align="center" style={{ color: "#000000" }}>
 SIGN IN
 </VuiTypography>

 <TextField
 style={{backgroundColor:"none",width:"100%"}}
 label="Email"
 variant="outlined"
 value={email}
 onChange={(e) => setEmail(e.target.value)}
 error={!email && message === 'Please enter your email.'}
 helperText={!email && message === 'Please enter your email.' ? message : ''}
 />
 <VuiTypography variant="button" color="error" align="center">
 {message}
 </VuiTypography>
 <VuiBox mt={4}>
 <VuiButton
 onClick={handleOtpRequest}
 size="large"
 style={{ fontSize: "small", marginTop: "10px", color: "white", width: "100%", backgroundColor: "#8BC63D" }}
 variant="contained"
 fullWidth
 disabled={loading}
 >
 {loading ? "Loading..." : "GET OTP"}
 </VuiButton>
 <VuiButton
 onClick={() => handleRoutes('/Welcome')}
 size="large"
 style={{ fontSize: "small", marginTop: "10px", color: "white", width: "100%", backgroundColor: "black" }}
 variant="contained"
 fullWidth
 >
 BACK
 </VuiButton>
 </VuiBox>
 </VuiBox>
 </CoverLayout>
 );
}

export default SignIn;
