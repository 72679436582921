import React, { useEffect, useState } from "react";
import { Link,useHistory } from "react-router-dom";
import jwt from 'jsonwebtoken';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import SignUpForm from "layouts/authentication/components/Validation";

// Images
import bgImage from "assets/images/background-cover-auth-signup.png";

function SignIn() {
  const history = useHistory();
  const token = localStorage.getItem('token');
  const [getrefby,setrefby]=useState('');
  const [getmatch,setmatch]=useState(true);
  const [getrefcodeby,setrefcodeby]=useState('');
  if(token){
    history.push('/Dashboard');
  }
  useEffect(() => {
    const saveReferral = (referral) => {
      localStorage.setItem('referral', referral);
      document.cookie = `referral=${referral}; path=/; max-age=31536000; SameSite=Lax`;
    };
    const match = localStorage.getItem('matching');
    if(!match){
      setmatch(false);
    }
    const urlParams = new URLSearchParams(window.location.search);
    const referral = urlParams.get('ref');

    if (referral) {
      saveReferral(referral);
    }
  }, []);

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };


// Retrieve referral code from URL parameters
  const urlParams = new URLSearchParams(window.location.search);
  let referral = urlParams.get('ref');

// If referral code is not in URL parameters, check the cookies
  if (!referral) {
    referral = getCookie('referral');
  }

// Save referral code in a cookie if it exists
  if (referral) {
    document.cookie = `referral=${referral}; path=/; max-age=31536000; SameSite=Lax`;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (referral) {
          const response = await fetch(`/checkReferral?value=${referral}`);
          const data = await response.json();
          if (data.status === 'success') {
            setrefby(data.name);
            setrefcodeby(data.code);
          } else {
            console.error(data.message);
          }
        }
      } catch (error) {
        console.error('Error fetching referral:', error);
      }
    };

    fetchData();
  }, [referral]);

  const handleRoutes = (path) => {
    history.push(path)
  }

  return (

    <CoverLayout
    top="40px"
    title="/Plan"
    color="white"
    description="Let’s get you activated!"
    image={bgImage}
    premotto={"COMMUNITY POWERED COMMERCE"}
    motto={"Togethër"}
    cardContent
    >
    {getrefby && (
      <VuiBox
      style={{background: "linear-gradient(to right, #000000, #808080)",borderRadius:"30px"}}
      component="form"
      role="form"
      borderRadius="inherit"
      p="45px"
      >
      <VuiTypography variant="h6" fontWeight="bold" align="left" style={{color:"#EAEAEA"}}>You have been referred by</VuiTypography>
      <VuiTypography mb={1}  variant="button" fontWeight="medium" align="left" style={{color:"#787575"}}>{getrefby}</VuiTypography>
      <VuiTypography mt={1}  variant="h6" fontWeight="bold" align="left" style={{color:"#EAEAEA"}}>Referral Code: </VuiTypography>
      <VuiTypography mt={1}  variant="button" fontWeight="medium" align="left" style={{color:"#787575"}}>{getrefcodeby}</VuiTypography>
      </VuiBox>
      )}
    <VuiBox mt={4}>
    <VuiButton onClick={() => handleRoutes('/RegisterForm')} size="large" style={{fontSize:"small",marginTop: "10px", color: "white", width: "100%", backgroundColor: "#8BC63D"}} variant="contained" fullWidth>Sign Up</VuiButton>
    <VuiButton onClick={() => handleRoutes('/Login')} size="large" style={{fontSize:"small",marginTop: "10px", color: "white", width: "100%", backgroundColor: "black"}} variant="contained" fullWidth>Sign In</VuiButton>
    </VuiBox>
    </CoverLayout>
    );
}

export default SignIn;
