import React, { useEffect, useState } from "react";
import { Link,useHistory } from "react-router-dom";
import jwt from 'jsonwebtoken';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import SignUpForm from "layouts/authentication/components/Validation";

// Images
import bgImage from "assets/images/background-cover-auth-signup.png";

function SignIn() {
  const history = useHistory();
  const token = localStorage.getItem('token');

  return (
    <CoverLayout
    top="40px"
    title="/Plan"
    color="white"
    description="Let’s get you activated!"
    image={bgImage}
    premotto={"COMMUNITY POWERED COMMERCE"}
    motto={"Togethër"}
    cardContent
    >
    <VuiBox mt={4}>
    <VuiButton size="large" style={{textTransform:"capitalize",fontSize:"small",marginTop: "10px", color: "white", width: "100%", backgroundColor: "#8BC63D"}} variant="contained" fullWidth>Buy With Credit / Debit Card</VuiButton>
    <VuiButton size="large" style={{textTransform:"capitalize",fontSize:"small",marginTop: "10px", color: "white", width: "100%", backgroundColor: "black"}} variant="contained" fullWidth>Manual Payment</VuiButton>
    </VuiBox>
    </CoverLayout>
    );
}

export default SignIn;