// Success component
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PageLayout from "examples/LayoutContainers/PageLayout";
import CircularProgress from '@mui/material/CircularProgress';
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/background-cover-auth-signup.png";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import lucky from 'assets/images/lucky.png';
import rebirth from 'assets/images/rebirth.png';
import VuiSnackbar from "components/VuiSnackbar";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiBadge from "components/VuiBadge";
import VuiButton from "components/VuiButton";

const Success = () => {
  const [loading, setLoading] = useState(true);
  const today = new Date();
  const formattedDate = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;
  const history = useHistory();
  const tokens = localStorage.getItem("token");
  const pkgname = localStorage.getItem("pkgname");

  if (!tokens) {
    history.push("/Welcome");
    return;
  }

  useEffect(() => {
    const fetchCustomerName = async () => {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        const sessionId = queryParams.get('session_id');
        const token = queryParams.get('token');
        const getOrderID = await fetch(`/session?session_id=${sessionId}&pkgname=${pkgname}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!getOrderID.ok) {
          throw new Error('Failed to Keep Session');
        }
        const data = await getOrderID.json();
        history.push({
          pathname: '/Dashboard',
        });
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(true);
      }
    };

    fetchCustomerName();
  }, [location.search, history]);

  if (loading) {
    return (
      <CoverLayout
      top="50px"
      title=""
      color="white"
      image={bgImage}
      premotto={"COMMUNITY POWERED COMMERCE"}
      motto={"Togethër"}
      cardContent
      >
      <Grid style={{ marginTop:"50px"}} item xs={12} lg={5} xl={5}>
      </Grid>
      </CoverLayout>
      );
  }

};

export default Success;
