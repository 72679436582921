import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import TextField from '@mui/material/TextField';
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/background-cover-auth-signup.png";
import { Input, Typography, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import axios from 'axios';
function VerifyOTP() {
  const [loading, setloading] = useState(false);
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const [resendDisabled, setResendDisabled] = useState(true); 
  const [countdown, setCountdown] = useState(120);
  const history = useHistory();
  const token = localStorage.getItem('token');

  if(token){
    history.push('/Dashboard');
  }

  const location = useLocation();
  const { firstName, lastName, nric, phoneNumber, email, referralCode } = location.state || {};

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          clearInterval(timer);
          setResendDisabled(false); 
        }
        return prevCountdown > 0 ? prevCountdown - 1 : 0;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleRoutes = (path) => {
    history.push(path);
  };

//send back the prefilled data
  const editback = () => {
    history.push('/RegisterForm', {
      fname: firstName,
      lname: lastName,
      ic: nric,
      pn: phoneNumber,
      em: email,
      rc: referralCode,
    });
  };

  const handleLogin = async () => {
    setloading(true);
    try {
      const verifyOtpResponse = await axios.post('/verifyRegisterOtp', {
        email: email,
        otp: otp,
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      if (!verifyOtpResponse.status === 200) {
        setloading(false);
        throw new Error('Failed to verify OTP');
      }

      const verifyOtpData = verifyOtpResponse.data;

      if (verifyOtpData.status === 'fail') {
        setloading(false);
        setMessage('Invalid OTP. Please try again.');
        return;
      }
    // Step 2: Register User
      const registerResponse = await axios.post('/register', {
        fname: firstName,
        lname: lastName,
        nric: nric,
        phone_country: '60',
        phone: phoneNumber,
        email: email,
        referral_code: referralCode,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!registerResponse.status === 200) {
        throw new Error('Failed to Register');
      }

      const registerData = registerResponse.data;

      if (registerData.status === 'fail') {
        throw new Error(registerData.message);
      }
      await new Promise((resolve) => {
        localStorage.setItem('token', registerData.token);
        resolve();
      });

      history.push('/Dashboard');
    } catch (error) {
      setloading(false);
      console.error('Error registering:', error.message);
      setMessage('Failed to register. Please try again.');
    }
  };

  const handleResendOTP = () => {
    setCountdown(120);
    setResendDisabled(true);
  };

  return (
    <CoverLayout
    top="40px"
    title="OTP Verification"
    color="white"
    description="Please enter the OTP sent to your email."
    image={bgImage}
    premotto={"COMMUNITY POWERED COMMERCE"}
    motto={"Togethër"}
    cardContent
    >
    <VuiBox
    style={{ background: "linear-gradient(to right, #FFFFFF, #FFFFFF)", borderRadius: "30px" }}
    borderRadius="inherit"
    p="45px"
    >
    <VuiTypography variant="body2" fontWeight="regular" align="center" size='10px' style={{color:"#000000"}}>We have sent an OTP to your email <span>{email}</span></VuiTypography>
    <VuiTypography variant="body2" fontWeight="regular" align="center" size='10px' style={{color:"#000000",marginTop:"24px"}}>
    {resendDisabled ? `Resend OTP(${countdown})` : "Resend OTP"}
    </VuiTypography>

    <Box display="flex" justifyContent="center" mt={2}>
    <TextField
    placeholder="OTP"
    variant="outlined"
    value={otp} 
    onChange={(e) => setOtp(e.target.value)} 
    inputProps={{ style: { textAlign: 'center', fontWeight: 'bold',fontSize:"large" } ,maxLength: 4 }} 
    />
    </Box>

    <VuiTypography variant="button" color="error" align="center">
    {message}
    </VuiTypography>

    <VuiButton
    onClick={handleLogin}
    size="large"
    style={{fontSize:"small",marginTop: "10px", color: "white", width: "100%", backgroundColor: "#8BC63D"}}
    variant="contained"
    fullWidth
    disabled={loading}
    >
    {loading ? "Loading..." : "Continue"}
    </VuiButton>

    <VuiButton
    onClick={editback}
    size="large"
    style={{fontSize:"small",marginTop: "10px", color: "white", width: "100%", backgroundColor: "black"}}
    variant="contained"
    fullWidth
    disabled={resendDisabled}
    >
    Resend/Edit
    </VuiButton>
    </VuiBox>
    </CoverLayout>
    );
  }

  export default VerifyOTP;
