import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import PageLayout from "examples/LayoutContainers/PageLayout";
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory, useLocation } from 'react-router-dom';

const CheckoutButton = () => {
  const location = useLocation();
  const history = useHistory();
  const { packageId} = location.state || {};

  const [stripe, setStripe] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");

  if (!token) {
    history.push("/Welcome");
  }
  useEffect(() => {
    const initializeStripe = async () => {
      try {
        const stripe = await loadStripe('pk_live_slx6i1FJQlPXEQPPfLD4WAhg00Msvbti4l');
        setStripe(stripe);
      } catch (error) {
        setError(error.message);
      }
    };

    initializeStripe();
  }, []);

  useEffect(() => {
    if (!stripe && token) return;

    const handleClick = async () => {
      const { error } = await stripe.redirectToCheckout({
        lineItems: [{ price: packageId, quantity: 1 }],
        mode: 'payment',
        successUrl: `${window.location.origin}/payment-success?session_id={CHECKOUT_SESSION_ID}&token=${token}`,
        cancelUrl: `${window.location.origin}/Packages`,
      });
      if (error) {
        setError(error.message);
      }
    };
    if(token){
      handleClick();
    }
  }, [stripe, token, packageId]);

        if (loading) {
          return (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'linear-gradient(90deg, #1a123f 30%, #461248 70%)',
            }}>
            <CircularProgress style={{ width: '100px', height: '100px', color: '#FFFFFF' }} />
            </div>
            );
        }

        return (
          <PageLayout>
      {/* Content */}
          </PageLayout>
          );
      };

      export default CheckoutButton;
