import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import jwt from "jsonwebtoken";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";
import Avatar from "@mui/material/Avatar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import SignUpForm from "layouts/authentication/components/Validation";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Check } from "@mui/icons-material";

// Images
import bgImage from "assets/images/DashboardBgImage.png";
import VuiAvatar from "components/VuiAvatar";
import DashboardLayout from "layouts/DashboardLayout";

function Home() {
  const history = useHistory();
  const token = localStorage.getItem("token");
  console.log(token);
  //for profile get set
  const [getname, setname] = useState(0);
  const [getref, setref] = useState("");
  const [getRank, setRank] = useState("");
  const [getrefby, setrefby] = useState("");
  const [gettotallvl, settotallvl] = useState("");
  const [getPhone, setPhone] = useState("");
  const [getReferralsList, setReferralsList] = useState([]);
  const [getPurchase, setPurchase] = useState(1);
  const [getPurchaseTitle, setPurchaseTitle] = useState(1);
  const [getPurchaseDesc, setPurchaseDesc] = useState(1);
  const [getPurchaseDesc2, setPurchaseDesc2] = useState(1);
  const [open, setOpen] = React.useState(false);

  //for transaction history get set
  const [getTransactionHistoryList, setTransactionHistoryList] = useState([]);

  //check login
  if (!token) {
    history.push("/Welcome");
  }
  //check login
  const logout = async () => {
    localStorage.clear();
    window.location.reload();
  };
  const handleRoutes = (path) => {
    history.push(path);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(getref);
    setOpen(true);

  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  async function fetchData() {
    try {
      const response = await fetch("/search", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log(data);
      if (!data.profile.purchased) {
        history.push('/Packages');
      }
      setref(data.profile.referral_text);
      setname(data.profile.name);
      settotallvl(data.profile.totallvl);
      setrefby(data.profile.refby);
      setref(data.profile.referral_code);
      setPhone("+" + data.profile.phone_country + data.profile.phone);
      setReferralsList(data.profile.ref);
      setRank(data.profile.rank);
      setPurchase(data.profile.purchased);
      setPurchaseTitle(data.profile.purchased_title);
      setPurchaseDesc(data.profile.purchased_description);
      setPurchaseDesc2(data.profile.purchased_description2);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }

    try {
      const response = await fetch("/getTransaction", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log(data.data);
      setTransactionHistoryList(data.data);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  const CustomIcon = ({ active, completed, icon }) => {
    const contents = completed ? <Check fontSize="inherit" /> : icon;
    return (
      <div
      style={{
        backgroundColor: active || completed ? "#8BC63D" : "gray",
        color: "#8BC63D",
        minHeight: "20px",
        minWidth: "20px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
        fontSize: "1rem",
      }}
      >
        {/* {contents} */}
      </div>
      );
  };

  return (
    <DashboardLayout
    top="40px"
    title="/Dashboard"
    color="white"
    description="Let’s get you activated!"
    image={bgImage}
    premotto={"COMMUNITY POWERED COMMERCE"}
    motto={"Togethër"}
    cardContent
    >
      {/* your account */}
    <VuiTypography variant="h5" fontWeight="bold" align="center" style={{ color: "black" }}>
    Your Account
    </VuiTypography>
    <VuiBox
    style={{
      background: "linear-gradient(to right, #000000, #808080)",
      borderRadius: "30px",
      marginTop: "20px",
    }}
    component="form"
    role="form"
    borderRadius="inherit"
    p="30px 40px"
    >
    <Stack mb={1} direction="row" spacing={2} alignItems="center">
    <item>
    {" "}
    <Icon fontSize="large" size="large" style={{ color: "#8BC63D" }}>
    person
    </Icon>
    </item>
    <item>
    {" "}
    <VuiTypography variant="h6" fontWeight="bold" align="left" style={{ color: "#EAEAEA" }}>
    {getname}
    </VuiTypography>
    </item>
    </Stack>

    <Stack mt={1} direction="row" spacing={2} alignItems="center">
    <item>
    {" "}
    <Icon fontSize="large" size="large" style={{ color: "#8BC63D" }}>
    call
    </Icon>
    </item>
    <item>
    {" "}
    <VuiTypography variant="h6" fontWeight="bold" align="left" style={{ color: "#EAEAEA" }}>
    {getPhone}
    </VuiTypography>
    </item>
    </Stack>
    <Stack mt={1} direction="row" spacing={2} alignItems="center">
    <item>
    {" "}
    <Icon fontSize="large" size="large" style={{ color: "#8BC63D" }}>
    stars
    </Icon>
    </item>
    <Stack mt={1} direction="row" spacing={2}>
    <item>
    {" "}
    <VuiTypography
    variant="h6"
    fontWeight="bold"
    align="left"
    style={{ color: "#EAEAEA" }}
    >
    Rank : {getRank}{" "}
    </VuiTypography>
    </item>
    <item>
    {" "}
    <Icon size="medium" style={{ color: "orange" }}>
    star
    </Icon>
    </item>
    </Stack>
    </Stack>
    </VuiBox>

      {/* your plan */}
    <VuiTypography
    variant="h5"
    fontWeight="bold"
    align="center"
    marginTop="30px"
    style={{ color: "black" }}
    >
    Your Plan
    </VuiTypography>
    <VuiBox
    style={{ background: "white", borderRadius: "30px", marginTop: "10px" }}
    component="form"
    role="form"
    borderRadius="inherit"
    p="30px 40px"
    >
    <VuiTypography
    variant="h5"
    fontSize="16pt"
    fontWeight="bold"
    align="left"
    style={{ color: "black" }}
    >
    <div dangerouslySetInnerHTML={{ __html: getPurchaseTitle }} />
    </VuiTypography>
    <VuiTypography
    variant="h6"
    marginTop="10px"
    fontSize="14pt"
    fontWeight="semibold"
    align="left"
    style={{ color: "black" }}
    sx={{ textDecoration: "underline" }}
    >
    {" "}
    {getPurchaseDesc}
    </VuiTypography>
    <VuiTypography
    variant="h6"
    fontSize="14pt"
    fontWeight="regular"
    align="left"
    style={{ color: "grey" }}
    >
    {getPurchaseDesc2}
    </VuiTypography>
    </VuiBox>
    {!getPurchase && (
      <VuiBox mt={4}>
      <VuiButton
      size="large"
      style={{
        fontSize: "small",
        marginTop: "10px",
        color: "white",
        width: "100%",
        backgroundColor: "#8BC63D",
      }}
      variant="contained"
      fullWidth
      >
      BUY PLAN
      </VuiButton>
      </VuiBox>
      )}
      {/* Refferal Program */}
    <VuiTypography
    variant="h5"
    fontWeight="bold"
    align="center"
    marginTop="30px"
    style={{ color: "black" }}
    >
    Referral Program
    </VuiTypography>
    <VuiBox
    style={{ background: "white", borderRadius: "30px", marginTop: "20px" }}
    borderRadius="inherit"
    p="30px 10px"
    >
    <Grid container spacing={0.5} style={{ display: 'flex', alignItems: 'center',flexDirection:'row',justifyContent:'flex-start'}} >
    <Grid item >
    {" "}
    <Icon fontSize="large" size="large" style={{ color: "black" }}>
    info
    </Icon>
    </Grid>

    <Grid item xs={9}>
    {" "}
    <VuiTypography fontSize="12pt" variant="h6" fontWeight="bold" align="left" style={{ color: "black" }}>
    Your referral code : {getref}
    </VuiTypography>
    </Grid>

    <Grid item>
    <IconButton aria-label="copy" onClick={handleCopy}>
    <Icon variant="contained" fontSize="large" size="large" style={{ color: "#8BC63D" }}>
    copy
    </Icon>
    </IconButton>
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
    Referral code copied to clipboard!
    </Alert>
    </Snackbar>
    </Grid>
    </Grid>
        {/* <Stack mb={1} direction="row" spacing={0.5} alignItems="center" justifyContent="space-between">
          <item>
            {" "}
            <Icon fontSize="large" size="large" style={{ color: "black" }}>
              info
            </Icon>
          </item>
          <item>
            {" "}
            <VuiTypography variant="h6" fontWeight="bold" align="left" style={{ color: "black" }}>
              Your referral code : {getref}
            </VuiTypography>
          </item>
          <item >
            <IconButton aria-label="copy" onClick={handleCopy}>
              <Icon variant="contained" fontSize="large" size="large" style={{ color: "#8BC63D" }}>
                copy
              </Icon>
            </IconButton>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                Referral code copied to clipboard!
              </Alert>
            </Snackbar>
          </item>
        </Stack> */}
    </VuiBox>

    <VuiBox
    style={{ background: "white", borderRadius: "30px", marginTop: "20px" }}
    component="form"
    role="form"
    borderRadius="inherit"
    p="30px 10px"
    >
    <Stack mb="5px" direction="row" spacing={0.5} alignItems="center" justifyContent="flex-start">
    <item>
    {" "}
    <Icon fontSize="large" size="large" style={{ color: "black" }}>
    info
    </Icon>
    </item>
    <item>
    {" "}
    <VuiTypography variant="h6" fontWeight="bold" align="left" style={{ color: "black" }}>
    You are referred by :<br /> {getrefby}
    </VuiTypography>
    </item>
    </Stack>
    </VuiBox>

      {/* Total Refferal */}
    <VuiTypography
    variant="h5"
    fontWeight="bold"
    align="center"
    marginTop="30px"
    style={{ color: "black" }}
    >
    Total Referrals : {gettotallvl}
    </VuiTypography>
    <VuiBox
    style={{ background: "black", borderRadius: "30px", marginTop: "20px" }}
    component="form"
    role="form"
    borderRadius="inherit"
    p="5px 30px"
    >
    <Stepper orientation="vertical" style={{ color: "#8BC63D" }}>
    {getReferralsList.map((info) => (
      <Step key={info} active={true}>
      <StepLabel fontSize="14pt" StepIconComponent={CustomIcon}>
      <VuiTypography
      variant="h5"
      style={{ color: "#8BC63D" }}
      sx={{ textDecoration: "underline" }}
      >
      {info.tierName} : {info.count}
      </VuiTypography>
      </StepLabel>
      </Step>
      ))}
    </Stepper>
    </VuiBox>

    <VuiBox mt={4}>
    <VuiButton
    onClick={() => handleRoutes("/Refferal")}
    size="large"
    style={{
      fontSize: "small",
      marginTop: "10px",
      color: "white",
      width: "100%",
      backgroundColor: "#8BC63D",
    }}
    variant="contained"
    fullWidth
    >
    VIEW MORE
    </VuiButton>
    </VuiBox>

      {/* Transaction history */}
    <VuiTypography
    variant="h5"
    fontWeight="bold"
    align="center"
    marginTop="30px"
    style={{ color: "black" }}
    >
    Transaction history
    </VuiTypography>
    <VuiBox
    style={{ background: "white", borderRadius: "30px", marginTop: "10px" }}
    component="form"
    role="form"
    borderRadius="inherit"
    p="30px 40px"
    >
    {getTransactionHistoryList.map((info) => (
      <Stack mb={1} direction="column" spacing={0.5} alignItems="left">
      <item>
      {" "}
      <VuiTypography
      variant="h6"
      fontSize="14pt"
      fontWeight="semibold"
      align="left"
      style={{ color: "black" }}
      >
      Order # :{" "}
      <VuiTypography
      display={"inline"}
      variant="button"
      align="left"
      >
      {info.order_id}
      </VuiTypography>
      </VuiTypography>
      </item>
      <item>
      {" "}
      <VuiTypography
      variant="h6"
      fontSize="14pt"
      fontWeight="bold"
      align="left"
      style={{ color: "black" }}
      >
      Status :{" "}
      <VuiTypography
      display={"inline"}
      variant="h6"
      fontWeight="normal"
      align="left"
      style={{ color: info.status === "Pending Approve" ? "red" : "green" }}
      >
      {" "}
      {info.status}
      </VuiTypography>{" "}
      </VuiTypography>
      </item>
      <item>
      {" "}
      <VuiTypography
      variant="h6"
      fontSize="14pt"
      fontWeight="bold"
      align="left"
      style={{ color: "black" }}
      >
      Date:{" "}
      <VuiTypography
      display={"inline"}
      variant="h6"
      align="left"
      >
      {info.date}
      </VuiTypography>
      </VuiTypography>
      </item>
      <item>
      {" "}
      <VuiTypography
      variant="h6"
      fontSize="14pt"
      fontWeight="bold"
      align="left"
      style={{ color: "black" }}
      >
      Paid :{" "}
      <VuiTypography
      display={"inline"}
      variant="h6"
      align="left"
      >
      {info.amount}
      </VuiTypography>
      </VuiTypography>
      </item>
      <Divider
      p="20px"
      sx={{ width: "100%", backgroundColor: "black", border: "0.7px solid" }}
      ></Divider>
      </Stack>
      ))}
    </VuiBox>
    <VuiBox mt={4}>
    <VuiButton
    size="large"
    style={{
      fontSize: "small",
      marginTop: "50px",
      color: "white",
      width: "100%",
      backgroundColor: "black",
    }}
    variant="contained"
    fullWidth
    onClick={logout}
    >
    LOG OUT
    </VuiButton>
    </VuiBox>
    </DashboardLayout>
    );
}

export default Home;
