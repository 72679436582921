import React, { useEffect, useState } from "react";
import { Link,useHistory } from "react-router-dom";
import jwt from 'jsonwebtoken';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Divider from '@mui/material/Divider';
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import VuiAlert from "components/VuiAlert";
import Snackbar from "@mui/material/Snackbar";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import SignUpForm from "layouts/authentication/components/Validation";

// Images
import bgImage from "assets/images/DashboardBgImage.png";
import Swal from "sweetalert2";
import DashboardLayout from "layouts/DashboardLayout";

function ManualPaymentPage() {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const token = localStorage.getItem('token');
  if (!token) {
    history.push("/Welcome");
    return;
  }
  const back = () => {
    history.push("/Pick-Payment");
  }
  const handleCopy = () => {
    setOpen(true);
    navigator.clipboard.writeText(accountDetails.accountNo);
  };
    const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const handleColse = () => {
    Swal.fire({
      title: "Close Page?",
      color:"black",
      text: "Data will be deleted after closing this page",
      showCancelButton: true,
      cancelButtonColor: "black",
      confirmButtonText: "Yes, close",
      showCloseButton: true,
      reverseButtons: true,
      confirmButtonColor: "#3085d6"
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('yes close');
        history.push("/Pick-Payment");
        // Swal.fire({
        //   title: "Deleted!",
        //   text: "Your file has been deleted.",
        //   icon: "success"
        // });
      }
    });
    };
    const uploadPaymentAlert = () => {
      Swal.fire({
        title: "Send Proof Of Payment",
        color:"black",
        text: "Please send your proof of payment to you Referrer and allow 48 hours for transaction to be manually verified.",
        showCancelButton: false,
        cancelButtonColor: "black",
        confirmButtonText: "Ok",
        showCloseButton: true,
        reverseButtons: true,
        confirmButtonColor: "#3085d6"
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('yes close');
        }
      });
      };
      const handleUploadPayment = async () => {
        try {
          const pkgname = localStorage.getItem("pkgname");
          const getOrderID = await fetch(`/session_manual?pkgname=${pkgname}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          if (!getOrderID.ok) {
            throw new Error('Failed to Keep Session');
          }
          const data = await getOrderID.json();
          history.push({
            pathname: '/Dashboard',
          });
        } catch (error) {
          console.error('Error:', error);
        } finally {
          setLoading(true);
        }
      };
      const accountDetails = {
        accountNo : "120600010047514",
        accName : "Fireworks Solutions sdn bhd",
        bank : "Alliance Bank",
        note: "Please allow 48 hours for transaction to be manually verified."
      }

      return (
        <DashboardLayout
        top="40px"
        title="/Plan"
        color="white"
        description="Let’s get you activated!"
        image={bgImage}
        premotto={"COMMUNITY POWERED COMMERCE"}
        motto={"Togethër"}
        cardContent
        >
        <VuiBox
        style={{ background: "white", borderRadius: "30px", marginTop: "20px" }}
        component="form"
        role="form"
        borderRadius="inherit"
        p="30px 30px"
        >
        <Icon onClick={handleColse} fontSize="large"  size="large"  style={{ float:"right", color:"black", display:"flex"}}>close</Icon>
        <VuiTypography mt="30px" variant="h5" fontWeight="bold" align="center" style={{ color: "black" }}>Manual Payment</VuiTypography>


        <Divider sx={{ width: "100%", backgroundColor: "black", border: '0.7px solid' }}></Divider>

        <VuiTypography mt="50px" variant="h5" fontWeight="bold" align="center" style={{color:"black",textDecoration:"underline"}}>Step 1 : Bank Transfer</VuiTypography>
        <VuiTypography mt="30px" variant="h6" fontWeight="bold" align="left" style={{ color: "black" }}>Bank Account No.</VuiTypography>

        <VuiBox
        style={{background: "lightgrey",borderRadius:"16px",marginTop:"10px"}}
        component="form"
        role="form"
        borderRadius="inherit"
        p="10px 20px"
        >
        <Stack direction="row" spacing={3} alignItems="center" width="100%" justifyContent="space-between">
        <item> <VuiTypography variant="h6" fontWeight="bold" align="left" style={{color:"black"}}>{accountDetails.accountNo}</VuiTypography></item>
        <item> <IconButton aria-label="copy" onClick={handleCopy}><Icon variant="contained" fontSize="large"  size="large" style={{color:"#8BC63D", float:"right"}}>copy</Icon></IconButton></item>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        copied to clipboard!
        </Alert>
        </Snackbar>
        </Stack>

        </VuiBox>

        <Stack mt="10px" direction="column" spacing={0.5} alignItems="left">
        <item> <VuiTypography variant="h6" fontSize="14pt" fontWeight="bold" align="left" style={{color:"black"}}>Bank :  <VuiTypography display={'inline'} fontSize="14pt" variant="h6" fontWeight="regular" align="left" style={{color:"grey"}}>{accountDetails.bank}</VuiTypography> </VuiTypography></item>
        <item> <VuiTypography variant="h6" fontSize="14pt" fontWeight="bold" align="left" style={{color:"black"}}>Acc Name : <VuiTypography display={'inline'} fontSize="14pt" variant="h6" fontWeight="regular" align="left" style={{color:"grey"}}> {accountDetails.accName}</VuiTypography> </VuiTypography></item>
        <item> <VuiTypography variant="h6" fontSize="14pt" fontWeight="bold" align="left" style={{color:"black"}}>(Platform Provider)</VuiTypography></item>

        </Stack>

        <VuiTypography mt="50px" variant="h5" fontWeight="bold" align="center" style={{color:"black",textDecoration:"underline"}}>Step 2: Upload Payment Receipt</VuiTypography>

        <VuiTypography m="30px 10px" fontSize="12pt" variant="h6" fontWeight="semibold" align="left" style={{color:"#787575"}}>Please send your proof of payment to your Referrer.</VuiTypography>
{/*      <VuiBox
      style={{background: "lightgrey",borderRadius:"16px",marginTop:"30px"}}
      component="form"
      role="form"
      borderRadius="inherit"
      p="5px 5px"
      onClick={uploadPaymentAlert}
      >
      <Stack direction="row" spacing="1px" alignItems="center" justifyContent="center">
      <item> <IconButton aria-label="upload"><Icon variant="contained" fontSize="large"  size="large" style={{color:"black"}}>upload</Icon></IconButton></item>
      <item> <VuiTypography variant="h6" fontSize="14pt" fontWeight="semibold" align="left" style={{color:"#787575"}}>Upload Payment</VuiTypography></item>

      </Stack>

      </VuiBox>*/}

        <VuiTypography mt="50px" variant="h5" fontWeight="bold" align="center" style={{color:"black",textDecoration:"underline"}}>Step 3:  Submit Transaction</VuiTypography>
        <VuiTypography m="30px 10px" fontSize="12pt" variant="h6" fontWeight="semibold" align="left" style={{color:"#787575"}}>Please allow 48 hours for transaction to be manually verified.</VuiTypography>

        <VuiBox mt={4}>
        <VuiButton  size="medium" style={{fontSize:"small",marginTop: "10px", color: "white", width: "100%", backgroundColor: "#8BC63D"}} variant="contained" fullWidth onClick={handleUploadPayment}>SUBMIT</VuiButton>
        <VuiButton  size="medium" style={{fontSize:"small",marginTop: "10px", color: "white", width: "100%", backgroundColor: "black"}} variant="contained" fullWidth onClick={back}>CANCEL</VuiButton>
        </VuiBox>

        </VuiBox>
        </DashboardLayout>
        );
}

export default ManualPaymentPage;